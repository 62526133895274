<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 xl6 lg6>
        <v-card elevation="12">
          <v-app-bar flat color="primary">
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>
            <v-toolbar-title class="title white--text pl-0">
              Login
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" icon></v-btn>
          </v-app-bar>
          <v-card-text>
            <v-alert elevation="8" v-show="loginFail" type="error">
              {{ loginFailedMessage }}
            </v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                prepend-icon="mdi-email"
                :rules="emailRules"
                label="E-mail"
                autocomplete="username"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="password"
                prepend-icon="mdi-lock"
                type="password"
                label="Password"
                autocomplete="current-password"
                required
              >
              </v-text-field>
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading || !valid"
                color="primary"
                @click="submitLogin()"
              >
                Login
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                color="secondary"
                class="mr-4"
                @click="$router.push({ name: 'forgot' })"
              >
                Forgot Password
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "Login",

  data: () => ({
    loading: false,
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    password: "",
    loginFail: false,
    loginFailedMessage: ""
  }),
  created: function() {
    if (this.$store.state.user) {
      this.$router.push("/select");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.valid) this.submitLogin();
    },
    submitLogin() {
      if (!this.valid) return;

      this.loader = true;
      this.loading = true;

      axios
        .post("/api/login", {
          email: this.email,
          password: this.password
        })
        .then(res => {
          if (res.status === 200 &&
            res.headers["content-type"] === "application/json; charset=utf-8"
) {
            this.loginFail = false;
            this.loginFailedMessage = "";
            const d = res.data;
            this.$store.commit("auth", {
              email: d.email,
              name: `${d.profile.first_name} ${d.profile.last_name}`,
              role: d.role,
              token: d.token
            });
            this.$router.push("/select");
          } else {
            this.loginFail = true;
            this.loginFailedMessage = "Invalid email or password.";
          }
        })
        .catch(() => {
          this.loginFail = true;
          this.loginFailedMessage = "Invalid email or password.";
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
